import React, { useState } from "react";
import Slider from "react-slick";
import { URI } from "../../constants/constants";
export default function Gallery({ gallery }) {
  const [activeImage, setActiveImage] = useState(null);
  var settings = {
    nav: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <div>
      <Slider {...settings}>
        {gallery &&
          gallery.map((pic) => {
            return (
              <div>
                <img
                  src={`${URI}${pic.url}`}
                  onClick={() => setActiveImage(pic.url)}
                  style={{
                    height: "500px",
                    width: "100%",
                    objectFit: "contain",
                    objectPosition: "top",
                  }}
                />
              </div>
            );
          })}
      </Slider>
      {activeImage && (
        <div
          onClick={() => setActiveImage(null)}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0,0.8)",
            zIndex: "9999",
          }}
        >
          <img
            src={`${URI}${activeImage}`}
            alt="Kilkari Care Foundation"
            style={{ width: "100%", height: "100vh", objectFit: "contain" }}
          />
        </div>
      )}
    </div>
  );
}
