import api from "../utils/api";
import {
  GET_DONATIONS,
  TOTAL_DONATIONS,
  ADD_DONATION,
  GET_DONATION,
  RESET_DONATION,
  DONATIONS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countDonation =
  ({ q, createdAt_lte, createdAt_gte }) =>
    async (dispatch) => {
      try {
        let query = "/donations/count?";
        if (q) {
          let newQ = ``;
          newQ += `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          let newCreatedAt_gte = ``;
          newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
          query = query + newCreatedAt_gte;
        }
        if (createdAt_gte) {
          let newCreatedAt_gte = ``;
          newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
          query = query + newCreatedAt_gte;
        }

        let res = await api.get(query);
        dispatch({
          type: TOTAL_DONATIONS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: DONATIONS_ERROR,
          payload: err,
        });
        if (err.response && err.response.data) {
          const errors = err.response.data.message;
          if (errors && errors.length > 0) {
            const mainError = errors[0];
            if (mainError.messages) {
              mainError.messages.forEach((error) =>
                dispatch(setAlert(error.message, "danger"))
              );
            }
          }
        }
      }
    };

//getDonationBySlug
export const getDonationById = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_DONATION,
    });
    const res = await api.get("/donations?id=" + id);
    if (res.data && res.data.length > 0) {
      dispatch({
        type: GET_DONATION,
        payload: res.data[0],
      });
    } else {
      dispatch(setAlert("No Donation Found", "danger"));
    }
  } catch (err) {
    dispatch({
      type: DONATIONS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};


// Get posts
export const getDonations =
  ({
    limit = 10000,
    page = 1,
    sort = "DESC",
    q,
    createdAt_lte,
    createdAt_gte,
  }) =>
    async (dispatch) => {
      try {
        dispatch({
          type: RESET_DONATION,
        });
        if (page) {
          const start = (page - 1) * limit;
          let query = `/donations?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
          if (q) {
            const newQ = `&_q=${q}`;
            query = query + newQ;
          }
          if (createdAt_lte) {
            const newLTE = `&createdAt_lte=${createdAt_lte}`;
            query = query + newLTE;
          }
          if (createdAt_gte) {
            const newGTE = `&createdAt_gte=${createdAt_gte}`;
            query = query + newGTE;
          }

          const res = await api.get(query);

          dispatch({
            type: GET_DONATIONS,
            payload: res.data,
          });
        }
      } catch (err) {
        dispatch({
          type: DONATIONS_ERROR,
          payload: err,
        });
        if (err.response && err.response.data) {
          const errors = err.response.data.message;
          if (errors && errors.length > 0) {
            const mainError = errors[0];
            if (mainError.messages) {
              mainError.messages.forEach((error) =>
                dispatch(setAlert(error.message, "danger"))
              );
            }
          }
        }
      }
    };
// Get posts
export const getDonation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_DONATION,
    });
    const res = await api.get("/donations/" + id);

    dispatch({
      type: GET_DONATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DONATIONS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addDonation = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/donations`, data);

    dispatch(setAlert("Donation Saved!", "success"));
    dispatch({
      type: ADD_DONATION,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DONATIONS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editDonation = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/donations/" + id, data);

    dispatch(setAlert("Donation Updated!", "success"));
  } catch (err) {
    dispatch({
      type: DONATIONS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deleteDonation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_DONATION,
    });
    const res = await api.delete("/donations/" + id);

    dispatch(setAlert("Donation Deleted!", "success"));
  } catch (err) {
    dispatch({
      type: DONATIONS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
