import React from 'react';

function PaymentForm() {
  return (
    <div className='text-center'>
      <a
        className='btn btn-donate-project'
        // href='https://pages.razorpay.com/kilkaricarefoundat'
        href='https://donate.kilkaricarefoundation.org/'
      >
        {' '}
        Donate Now{' '}
      </a>
      <p> DONATE NOW Using Net Banking / Debit Credit Card / UPI </p>

      <div className='text-center' style={{ padding: '50px 0px' }}>
        <img src='/images/qrcode.jpeg' alt='' style={{ maxWidth: '320px' }} />
      </div>
    </div>
  );
}

export default PaymentForm;
