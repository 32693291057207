import {
    GET_STUDENTS,
    TOTAL_STUDENTS,
    ADD_STUDENT,
    EDIT_STUDENT,
    GET_STUDENT,
    RESET_STUDENT,
    STUDENTS_ERROR,
} from "../actions/types";

const initialState = {
    students: null,
    student: null,
    total_students: 0,
    loading: true,
    error: {},
    student_message: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_STUDENTS:
            return {
                ...state,
                students: payload,
                loading: false,
                error: {},
            };

        case TOTAL_STUDENTS:
            return {
                ...state,
                total_students: payload,
                loading: false,
                error: {},
            };
        case RESET_STUDENT:
            return {
                ...state,
                students: null,
                student: null,
                loading: true,
                error: {},
            };

        case ADD_STUDENT:
            return {
                ...state,
                student_message: payload,
                loading: false,
                error: {},
            };
        case GET_STUDENT:
            return {
                ...state,
                student: payload,
                loading: false,
                error: {},
            };
        case EDIT_STUDENT:
            return {
                ...state,
                student_message: payload,
                loading: false,
                error: {},
            };

        case STUDENTS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
