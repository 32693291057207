import { combineReducers } from "redux";
import auth from "./auth";
import alert from "./alert";
import blog from "./blog";
import cause from "./cause";
import contact from "./contact";
import donation from "./donation";
import event from "./event";
import faq from "./faq";
import newsletter from "./newsletter";
import page from "./page";
import project from "./project";
import upcoming_project from "./upcoming_project";
import testimonial from "./testimonial";
import success_storie from "./success_storie";
import student from "./student"
export default combineReducers({
  auth,
  alert,
  blog,
  cause,
  contact,
  donation,
  event,
  faq,
  newsletter,
  page,
  project,
  upcoming_project,
  testimonial,
  success_storie,
  student,
});
