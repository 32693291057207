import React from "react";
import { Link } from "react-router-dom";

export default function Navbar({ setActiveMenu, activeMenu }) {
  return (
    <nav>
      <div
        className="nav-links"
        id="navLinks"
        style={{ right: activeMenu ? "0px" : "-250px" }}
      >
        <i className="fa fa-close" onClick={() => setActiveMenu(false)} />
        <ul>
          <li>
            <Link to="/" className="active">
              HOME
            </Link>
          </li>
          <li>
            <Link to="/about-us">ABOUT US</Link>
          </li>
          <li>
            <Link to="/projects"> PROJECTS</Link>
          </li>
          <li>
            <Link to="/causes">CAUSES</Link>
          </li>
          {
            // <li>
            //   <Link to="/students">STUDENTS</Link>
            // </li>
          }
          <li>
            <Link to="/upcoming-projects">UPCOMING PROJECT</Link>
          </li>
          <li>
            <Link to="/certificates">CERTIFICATES</Link>
          </li>

          <li>
            <Link to="/contact-us">CONTACT US</Link>
          </li>
        </ul>
      </div>
      <div className="bars" onClick={() => setActiveMenu(true)}>
        <i className="fa fa-bars" />
      </div>
    </nav>
  );
}
