import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrumb from "../components/BreadCrumb";
import { getSuccessStories } from "../actions/success_stories";
import Skeleton from "react-loading-skeleton";
import SuccessStorieCard from "../components/common/SuccessStorieCard";
const SuccessStories = ({ getSuccessStories, success_storie: { success_stories } }) => {
    useEffect(() => {
        getSuccessStories({ limit: 50 });
    }, []);
    return (
        <div>
            <div>
                <Header />
                <BreadCrumb title={"Success Stories"} />
                <section className="ptb-50 current-project">
                    <div className="container">
                        <div className="clearfix" />
                        <div className="row mt-5">
                            {success_stories ? (
                                success_stories.map((item) => {
                                    return <SuccessStorieCard item={item} />;
                                })
                            ) : (
                                <>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({ success_storie: state.success_storie });

const mapDispatchToProps = { getSuccessStories };

export default connect(mapStateToProps, mapDispatchToProps)(SuccessStories);
