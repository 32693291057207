import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrumb from "../components/BreadCrumb";
import { getStudents } from "../actions/students";
import Skeleton from "react-loading-skeleton";
import StudentCard from "../components/common/StudentCard";
export const Student = ({ getStudents, student: { students } }) => {
    useEffect(() => {
        getStudents({ limit: 50 });
    }, []);
    return (
        <div>
            <div>
                <Header />
                <BreadCrumb title={"Students"} />
                <section className="ptb-50 current-project">
                    <div className="container">
                        <div className="clearfix" />
                        <div className="row mt-5">
                            {students ? (
                                students.map((item) => {
                                    return <StudentCard item={item} />;
                                })
                            ) : (
                                <>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                    <div className="col-md-4">
                                        <Skeleton width="100%" height="415px" />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({ student: state.student })

const mapDispatchToProps = { getStudents };

export default connect(mapStateToProps, mapDispatchToProps)(Student)