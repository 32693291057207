import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrumbWithoutImage from "../components/BreadCrumbWithoutImage";
import { getSuccessStorieBySlug } from "../actions/success_stories";
import Skeleton from "react-loading-skeleton";
import { URI } from "../constants/constants";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import Gallery from "../components/common/Gallery";
import PaymentForm from "../components/common/PaymentForm";
const SingleSuccessStorie = ({
    getSuccessStorieBySlug,
    match,
    success_storie: { loading, success_storie },
    donation: { donations },
}) => {
    useEffect(() => {
        getSuccessStorieBySlug(match.params.slug);
    }, [match.params.slug]);
    const openFacebookURL = () => {
        console.log(window.location.pathname);

    };
    const [totalAchieved, setTotalAchieved] = useState(0);

    return (
        <div>
            <div>
                <Header />
                <BreadCrumbWithoutImage />
                {!loading ? (
                    success_storie && (
                        <section className=" donate-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8 mx-auto">
                                        <div className="single-cause-box">
                                            <div className="single-cause-name">
                                                <h3> {success_storie.name} </h3>
                                            </div>


                                            <div
                                                className="urgent-flex"
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    padding: "0px 0px",
                                                }}
                                            >

                                            </div>
                                            <img
                                                src={
                                                    success_storie.images && success_storie.images.length > 0
                                                        ? `${URI}${success_storie.images[0].url}`
                                                        : "/images/dreamstime_xxl_21162621-min-scaled.jpg"
                                                }
                                                style={{
                                                    marginBottom: 10,
                                                    height: "500px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                    objectPosition: "top",
                                                }}
                                            />
                                            <div className="single-cause-padd">
                                                {
                                                    //   <div className="box-content-flex">
                                                    //   <div className>
                                                    //     <h5>Goal</h5>
                                                    //     <span>₹ {cause.goal}</span>
                                                    //   </div>
                                                    //   <div className="bor" />
                                                    //   <div className="fifty">
                                                    //     <h6>
                                                    //       {parseInt((cause.achieved / cause.goal) * 100)} %
                                                    //     </h6>
                                                    //   </div>
                                                    //   <div className="bor" />
                                                    //   <div className>
                                                    //     <h5>Raised</h5>
                                                    //     <span>₹ {cause.achieved}</span>
                                                    //   </div>
                                                    // </div>

                                                    //   <div
                                                    //   className="give-progress-bar"
                                                    //   role="progressbar"
                                                    //   aria-valuemin={0}
                                                    //   aria-valuemax={100}
                                                    //   aria-valuenow={100}
                                                    // >
                                                    //   <span
                                                    //     style={{
                                                    //       width: `${parseInt(
                                                    //         (totalAchieved / cause.goal) * 100
                                                    //       )}%`,
                                                    //       background: "#D54427",
                                                    //     }}
                                                    //   />
                                                    // </div>
                                                }

                                                <div className="single-cause-name">


                                                    <div>
                                                        {success_storie.description && renderHTML(success_storie.description)}
                                                    </div>
                                                    {success_storie.documents && success_storie.documents.length > 0 && (
                                                        <>
                                                            <h3>Documents</h3>
                                                            <div className="">
                                                                <Gallery gallery={success_storie.documents} />
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </section>
                    )
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">
                                <Skeleton width="100%" height="100vh" />
                            </div>

                        </div>
                    </div>
                )}

                <Footer />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    success_storie: state.success_storie,
    donation: state.donation,
});

const mapDispatchToProps = { getSuccessStorieBySlug };

export default connect(mapStateToProps, mapDispatchToProps)(SingleSuccessStorie);
