import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
function BankDetails() {
  return (
    <div>
      <Header />
      <section className="page-banner">
        <div className="image-layer" />
        <div className="container">
          <h1 className="entry-title">Bank Details</h1>
        </div>
      </section>
      <section className="aboutus">
        <div className="container">
          <div className="row">
            <div>
              <p>
                Our Bank details to make donation from net-banking / Internet
                Banking. Offline Donation by Cheque/DD/Pay Order in Favor of
                “Kilkari Care Foundation”, Payable at New Delhi.
              </p>
            </div>
            <div className="col-md-4">
              <div className="content-about">
                <h4>Bank Name: Karur Vysya Bank</h4>
                <p> A/c Holder Name: Kilkari Care Foundation</p>
                <p> Type Of Account:Current</p>
                <p> A/c Number: 4113135000006559</p>
                <p> IFSC Code: KVBL0004113</p>
                <p> Branch Name: Laxmi Nagar, New Delhi, 110092</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="content-about">
                <h4>Bank Name: Axis Bank</h4>
                <p> A/c Holder Name: Kilkari Care Foundation</p>
                <p> Type Of Account: Saving</p>
                <p> A/c Number: 918010062864087</p>
                <p> IFSC Code: UTIB0002565</p>
                <p> Branch Name: Gandhi Nagar, New Delhi, 110092</p>
              </div>
            </div>
            <div className="col-md-4">
              <img src="/images/gpay.png" alt="Kilkari Care" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BankDetails;
