import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { getDonationById } from "../actions/donations"
import Skeleton from "react-loading-skeleton";
import { URI } from "../constants/constants";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import Gallery from "../components/common/Gallery";
import PaymentForm from "../components/common/PaymentForm";
import { useReactToPrint } from 'react-to-print';
import { getDonation } from "../actions/donations"
import moment from 'moment';


export const DonationReceipt = ({ getDonation, match, donation: { donation } }) => {
    useEffect(() => {
        getDonation(match.params.id)
    }, [match.params.id])

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const price_in_words = (price) => {
        var sglDigit = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"],
            dblDigit = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
            tensPlace = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"],
            handle_tens = function (dgt, prevDgt) {
                return 0 == dgt ? "" : " " + (1 == dgt ? dblDigit[prevDgt] : tensPlace[dgt])
            },
            handle_utlc = function (dgt, nxtDgt, denom) {
                return (0 != dgt && 1 != nxtDgt ? " " + sglDigit[dgt] : "") + (0 != nxtDgt || dgt > 0 ? " " + denom : "")
            };

        var str = "",
            digitIdx = 0,
            digit = 0,
            nxtDigit = 0,
            words = [];
        if (price += "", isNaN(parseInt(price))) str = "";
        else if (parseInt(price) > 0 && price.length <= 10) {
            for (digitIdx = price.length - 1; digitIdx >= 0; digitIdx--) switch (digit = price[digitIdx] - 0, nxtDigit = digitIdx > 0 ? price[digitIdx - 1] - 0 : 0, price.length - digitIdx - 1) {
                case 0:
                    words.push(handle_utlc(digit, nxtDigit, ""));
                    break;
                case 1:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 2:
                    words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] && 0 != price[digitIdx + 2] ? " and" : "") : "");
                    break;
                case 3:
                    words.push(handle_utlc(digit, nxtDigit, "Thousand"));
                    break;
                case 4:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 5:
                    words.push(handle_utlc(digit, nxtDigit, "Lakh"));
                    break;
                case 6:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 7:
                    words.push(handle_utlc(digit, nxtDigit, "Crore"));
                    break;
                case 8:
                    words.push(handle_tens(digit, price[digitIdx + 1]));
                    break;
                case 9:
                    words.push(0 != digit ? " " + sglDigit[digit] + " Hundred" + (0 != price[digitIdx + 1] || 0 != price[digitIdx + 2] ? " and" : " Crore") : "")
            }
            str = words.reverse().join("")
        } else str = "";
        return str

    }

    return (
        <div className='printable-wrapper'>
            {donation && (
                <>
                    <div style={{ textAlign: "right" }}>
                        <div className='btn btn-success' onClick={() => handlePrint()}> Save as PDF  </div>
                    </div>
                    <hr />
                    <div ref={componentRef}>
                        {/* header-start  */}
                        <header>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="header-logo-content">
                                            <div className="logo-receipt">
                                                <img src="/images/kilkarilogo-3.png" alt />
                                            </div>
                                            <div className="header-content pt-38">
                                                <div className="header-title">
                                                    <h2>KILKARI CARE FONDATION</h2>
                                                </div>
                                                <div className="header-tagline">
                                                    <p>Caring for Smile</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* header-ends  */}
                        {/* receipt-form-starts  */}
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="receipt-form">
                                            <div className="form-fields">
                                                <div className="row1">
                                                    <div className="invoice">
                                                        <p>Invoice No: <span id="invoice-no"> { }  </span></p>
                                                    </div>
                                                    <div className="date">
                                                        <p>Date: <span id="date">{moment(donation.createdAt).format('MM/DD/YYYY')}  </span></p>
                                                    </div>
                                                </div>
                                                <div className="row2">
                                                    <div className="registration">
                                                        <p>Registration :- 1823/2017</p>
                                                    </div>

                                                </div>
                                                <div className="row3">
                                                    <div className="thanks">
                                                        <p>Received with Thanks From <span id="received-from"> {donation.name} </span></p>
                                                    </div>

                                                </div>
                                                <div className="row4">
                                                    <div className="contact">
                                                        <p>Contact <span id="contact"> {donation.phone}</span></p>
                                                    </div>
                                                    <div className="email">
                                                        <p>Email ID <span id="email"> {donation.email}</span></p>
                                                    </div>
                                                    {
                                                        // <div className="empty-space"></div>
                                                    }

                                                </div>
                                                <div className="row5">
                                                    <div className="address">
                                                        <p>Address: <span id="address">{donation.description}</span></p>
                                                    </div>

                                                </div>
                                                <div className="row6">
                                                    <div className="donation-amount">
                                                        <p>Thankyou for your donation in the amount of ₹ <span id="donation-amount"> {donation.amount} </span></p>
                                                    </div>
                                                    <div className="mod-of-payment">
                                                        <p>Mode of payment <span id="mode-of-payment">Online Payment</span></p>
                                                    </div>

                                                </div>
                                                <div className="row7">
                                                    <div className="amount">
                                                        <p>Amount (in Words)₹ <span id="amount-in-words">{price_in_words(donation.amount)}</span></p>
                                                    </div>

                                                    <div className="email">
                                                        <p>Pan No. <span id="email"> {donation.pan}</span></p>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* receipt-form-ends  */}
                        {/* donation-mode-starts  */}
                        <section className="form-footer-text">
                            <div className="donation-mode-text">
                                <p>Donation Made to Kilkari Care Foundation are Exempted Under 80G(5) Vi of Income Tax Act 1961. <br /> of Income Tax Department India</p>
                            </div>
                        </section>
                        {/* donation-mode-ends  */}
                        <footer>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="footer-section">
                                            <div className="sec-footer">

                                            </div>
                                            <div className="sec-footer">
                                                {
                                                    //     <div className="approval-no text-align-c lh-17">
                                                    //     <p>Approval No - CIT (EXEMPTION)DELHI/80G/2019-20/A/10308</p>
                                                    // </div>
                                                    // <div className="order-no text-align-c lh-17 ">
                                                    //     <p>Order No - ITBA/EXM/S/80G/2019-20/1019507375(1)</p>
                                                    // </div>
                                                }

                                                <div className="order-no text-align-c lh-17 ">
                                                    <p>Unique Ragistration number - AAEAK4516AF20231</p>
                                                </div>
                                                <div className="order-no text-align-c lh-17 ">
                                                    <p>Document Identification Number - AAEAK4516AF2023101</p>
                                                </div>
                                                <div className="pan-no">
                                                    <p><strong>PAN No - AAEAK4516A</strong></p>
                                                </div>
                                                <div className="footer-thankyou-text">
                                                    <p>Thank you for your Generosity, we appreciate Your Support</p>
                                                </div>
                                            </div>
                                            <div className="sec-footer">
                                                <div className="for-kilkari">
                                                    <p>For KILKARI CARE FOUNDATION</p>
                                                </div>
                                                <div className="authorised-signature">
                                                    <p>Authorised Signatory</p>

                                                    <div className="kilkari-authorised-signature">
                                                        <img src="/images/kilkari-mohar-v3-removebg-preview.png" alt="" />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="address-content">
                                    <div className="address-info">
                                        <p>Address: S-522, School Block Sharkarpur, New Delhi - 110092 <br />
                                            Email: contact@kilkaricarefoundation.org, Website : www.kilkaricarefoundation.org. <br />
                                            Mob. 987-333-6611</p>
                                    </div>
                                </div>
                            </div>

                        </footer>
                    </div>
                    <hr />
                    <div className='text-center'>
                        <div className='btn btn-success' onClick={() => handlePrint()}> Save as PDF  </div>
                    </div>
                </>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({ donation: state.donation })

const mapDispatchToProps = { getDonation }

export default connect(mapStateToProps, mapDispatchToProps)(DonationReceipt)