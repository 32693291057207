import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrumbWithoutImage from "../components/BreadCrumbWithoutImage";
import { getUpcomingProjectsBySlug } from "../actions/upcoming_projects";
import Skeleton from "react-loading-skeleton";
import { URI } from "../constants/constants";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import Gallery from "../components/common/Gallery";
import PaymentForm from "../components/common/PaymentForm";
const SingleUpcomingProject = ({
  getUpcomingProjectsBySlug,
  match,
  upcoming_project: { loading, upcoming_project },
  donation: { donations },
}) => {
  useEffect(() => {
    getUpcomingProjectsBySlug(match.params.slug);
  }, [match.params.slug]);
  const openFacebookURL = () => {
    console.log(window.location.pathname);
    const url = `https://www.kilkaricarefoundation.org${window.location.pathname}`;

    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
      "facebook-share-dialog",
      "width=626,height=436"
    );
  };
  const [totalAchieved, setTotalAchieved] = useState(0);
  useEffect(() => {
    if (donations && upcoming_project) {
      const filteredData = donations.filter(
        (donation) =>
          donation.project == `/upcoming-projects/${upcoming_project.slug}` &&
          donation.is_paid
      );
      // console.log("FILTERED DATA", filteredData);
      let sumValue = 25000;
      if (filteredData.length > 0) {
        sumValue =
          sumValue +
          filteredData
            .map((item) => item.amount)
            .reduce((prev, next) => prev + next);
      }

      setTotalAchieved(sumValue);
    }
  }, [donations, upcoming_project]);
  return (
    <div>
      <div>
        <Header />
        <BreadCrumbWithoutImage />
        {!loading ? (
          upcoming_project && (
            <section className=" donate-page">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="single-cause-box">
                      <div className="single-cause-name">
                        <h3> {upcoming_project.title} </h3>
                      </div>
                      <div className="share-btn">
                        <a
                          href="#"
                          onClick={() => openFacebookURL()}
                          className="btn btn-facebook m-1"
                        >
                          <i className="fa fa-facebook" /> Facebook
                        </a>
                        <a
                          href={`https://api.whatsapp.com/send?text=https://www.kilkaricarefoundation.org${window.location.pathname}`}
                          className="btn btn-whatsapp"
                          target="_blank"
                        >
                          <i className="fa fa-whatsapp" /> Whatsapp
                        </a>
                      </div>

                      <div
                        className="urgent-flex"
                        style={{
                          position: "absolute",
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <div>
                          <a href="#" className="btn btn-verified">
                            Urgent
                          </a>
                        </div>
                        <div>
                          <a href="#" className="btn btn-verified-header">
                            <i className="fa fa-check" /> Verified by Kilkari
                            Care foundation
                          </a>
                        </div>
                      </div>
                      <img
                        src={
                          upcoming_project.featured_image
                            ? `${URI}${upcoming_project.featured_image.url}`
                            : "/images/dreamstime_xxl_21162621-min-scaled.jpg"
                        }
                        style={{
                          marginBottom: 10,
                          height: "500px",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "top",
                        }}
                      />
                      <div className="single-cause-padd">
                        {

                          //   <div className="box-content-flex">
                          //   <div className>
                          //     <h5>Goal</h5>
                          //     <span>₹ {upcoming_project.goal}</span>
                          //   </div>
                          //   <div className="bor" />
                          //   <div className="fifty">
                          //     <h6>
                          //       {parseInt(
                          //         (totalAchieved / upcoming_project.goal) * 100
                          //       )}{" "}
                          //       %
                          //     </h6>
                          //   </div>
                          //   <div className="bor" />
                          //   <div className>
                          //     <h5>Raised</h5>
                          //     <span>₹ {totalAchieved}</span>
                          //   </div>
                          // </div>
                          // <div
                          //   className="give-progress-bar"
                          //   role="progressbar"
                          //   aria-valuemin={0}
                          //   aria-valuemax={100}
                          //   aria-valuenow={100}
                          // >
                          //   <span
                          //     style={{
                          //       width: `${parseInt(
                          //         (totalAchieved / upcoming_project.goal) * 100
                          //       )}%`,
                          //       background: "#D54427",
                          //     }}
                          //   />
                          // </div>

                        }
                        <div className="single-cause-name">
                          <div>
                            {upcoming_project.description &&
                              renderHTML(upcoming_project.description)}
                          </div>
                          {upcoming_project.gallery &&
                            upcoming_project.gallery.length > 0 && (
                              <>
                                <h3>Gallery</h3>
                                <div className="">
                                  <Gallery gallery={upcoming_project.gallery} />
                                </div>
                              </>
                            )}

                          <PaymentForm />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className>
                      <div className="page-heading">
                        <div className="sidebar-form">
                          <h2>{upcoming_project.title}</h2>
                          <p>How much would you like to donate</p>
                          <hr />
                          <div className="box-content-flex">
                            <div className>
                              <h5>Goal</h5>
                              <span>₹ {upcoming_project.goal} </span>
                            </div>
                            <div className="bor" />
                            <div className="fifty">
                              <h6>
                                {parseInt(
                                  (totalAchieved / upcoming_project.goal) * 100
                                )}
                                %
                              </h6>
                            </div>
                            <div className="bor" />
                            <div className>
                              <h5>Raised</h5>
                              <span>₹ {totalAchieved}</span>
                            </div>
                          </div>
                          <div
                            className="give-progress-bar"
                            role="progressbar"
                            aria-valuemin={0}
                            aria-valuemax={100}
                            aria-valuenow={100}
                          >
                            <span
                              style={{
                                width: `${parseInt(
                                  (totalAchieved / upcoming_project.goal) * 100
                                )}%`,
                                backgroundColor: "#084a5a",
                              }}
                            />
                          </div>
                          <PaymentForm />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <Skeleton width="100%" height="100vh" />
              </div>
              <div className="col-md-4">
                <Skeleton width="100%" height="415px" />
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  upcoming_project: state.upcoming_project,
  donation: state.donation,
});

const mapDispatchToProps = { getUpcomingProjectsBySlug };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleUpcomingProject);
