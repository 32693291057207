import api from "../utils/api";
import {
  GET_UPCOMING_PROJECTS,
  TOTAL_UPCOMING_PROJECTS,
  ADD_UPCOMING_PROJECT,
  GET_UPCOMING_PROJECT,
  RESET_UPCOMING_PROJECT,
  UPCOMING_PROJECTS_ERROR,
} from "./types";

import { setAlert } from "./alert";
// Get posts
export const countUpcomingProjec =
  ({ q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      let query = "/upcoming-projects/count?";
      if (q) {
        let newQ = ``;
        newQ += `&_q=${q}`;
        query = query + newQ;
      }
      if (createdAt_lte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_lte=${createdAt_lte}`;
        query = query + newCreatedAt_gte;
      }
      if (createdAt_gte) {
        let newCreatedAt_gte = ``;
        newCreatedAt_gte += `&createdAt_gte=${createdAt_gte}`;
        query = query + newCreatedAt_gte;
      }

      let res = await api.get(query);
      dispatch({
        type: TOTAL_UPCOMING_PROJECTS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: UPCOMING_PROJECTS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };

// Get posts
export const getUpcomingProjecs =
  ({ limit = 10, page = 1, sort = "DESC", q, createdAt_lte, createdAt_gte }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: RESET_UPCOMING_PROJECT,
      });
      if (page) {
        const start = (page - 1) * limit;
        let query = `/upcoming-projects?_limit=${limit}&_start=${start}&_sort=createdAt:${sort}`;
        if (q) {
          const newQ = `&_q=${q}`;
          query = query + newQ;
        }
        if (createdAt_lte) {
          const newLTE = `&createdAt_lte=${createdAt_lte}`;
          query = query + newLTE;
        }
        if (createdAt_gte) {
          const newGTE = `&createdAt_gte=${createdAt_gte}`;
          query = query + newGTE;
        }

        const res = await api.get(query);

        dispatch({
          type: GET_UPCOMING_PROJECTS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: UPCOMING_PROJECTS_ERROR,
        payload: err,
      });
      if (err.response && err.response.data) {
        const errors = err.response.data.message;
        if (errors && errors.length > 0) {
          const mainError = errors[0];
          if (mainError.messages) {
            mainError.messages.forEach((error) =>
              dispatch(setAlert(error.message, "danger"))
            );
          }
        }
      }
    }
  };
// Get posts
export const getUpcomingProjectsBySlug = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_UPCOMING_PROJECT,
    });
    const res = await api.get("/upcoming-projects/?slug=" + id);
    if (res.data && res.data.length > 0) {
      dispatch({
        type: GET_UPCOMING_PROJECT,
        payload: res.data[0],
      });
    } else {
      dispatch(setAlert("No Upcoming Project found", "danger"));
    }
  } catch (err) {
    dispatch({
      type: UPCOMING_PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
// Get posts
export const getUpcomingProjec = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_UPCOMING_PROJECT,
    });
    const res = await api.get("/upcoming-projects/" + id);

    dispatch({
      type: GET_UPCOMING_PROJECT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPCOMING_PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const addUpcomingProjec = (data, history) => async (dispatch) => {
  try {
    const res = await api.post(`/upcoming-projects`, data);

    dispatch(setAlert("UpcomingProjec Saved!", "success"));
    dispatch({
      type: ADD_UPCOMING_PROJECT,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: UPCOMING_PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
export const editUpcomingProjec = (id, data, history) => async (dispatch) => {
  try {
    const res = await api.put("/upcoming-projects/" + id, data);

    dispatch(setAlert("UpcomingProjec Updated!", "success"));
  } catch (err) {
    dispatch({
      type: UPCOMING_PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};

export const deleteUpcomingProjec = (id) => async (dispatch) => {
  try {
    dispatch({
      type: RESET_UPCOMING_PROJECT,
    });
    const res = await api.delete("/upcoming-projects/" + id);

    dispatch(setAlert("UpcomingProjec Deleted!", "success"));
  } catch (err) {
    dispatch({
      type: UPCOMING_PROJECTS_ERROR,
      payload: err,
    });
    if (err.response && err.response.data) {
      const errors = err.response.data.message;
      if (errors && errors.length > 0) {
        const mainError = errors[0];
        if (mainError.messages) {
          mainError.messages.forEach((error) =>
            dispatch(setAlert(error.message, "danger"))
          );
        }
      }
    }
  }
};
