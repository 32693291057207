import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <footer>
        <section className="pt-50 footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-logo">
                  <a href="#">
                    {" "}
                    <img src="/images/kilkarilogo.png" />
                  </a>
                  <p>
                    Kilkari Care Foundation is a non-government organization
                    based in New Delhi, the sole purpose of the organization is
                    to work for social integration of the underprivileged
                    children & people, and differently-abled persons.
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className="footer-links">
                  <h3>PAGES</h3>
                  <ul>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/success-stories">Success Stories</Link>
                    </li>
                    <li>
                      <Link to="/students">Students</Link>
                    </li>
                    <li>
                      <Link to="/projects">Current Project</Link>
                    </li>
                    <li>
                      <Link to="/upcoming-projects">Upcoming Project</Link>
                    </li>
                    <li>
                      <Link to="/become-a-volunteer">Become A Volunteer</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Blogs</Link>
                    </li>
                    <li>
                      <Link to="/become-a-volunteer">Get Involved</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-links">
                  <h3>QUICK LINK</h3>
                  <ul>
                    <li>
                      <Link to="/policy/terms-and-condition">
                        Terms and condition
                      </Link>
                    </li>
                    <li>
                      <Link to="/policy/refund-policy">Refund Policy</Link>
                    </li>
                    <li>
                      <Link to="/policy/privacy-policy">Privacy Policy</Link>
                    </li>

                    <li>
                      <Link to="/faqs">FAQ</Link>
                    </li>
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                    <li>
                      <Link to="/bank-details">Bank Details</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer-links">
                  <h3>CONTACT US</h3>
                  {
                    // <p>B-10,S-522 School block shakarpur, Delhi-110092</p>
                  }
                  <p>S-522 School Block Shakarpur, 110092</p>
                  <span>+91-987-333-6611</span>
                  <br />
                  <span>+91-99719-11148</span>
                </div>
                <div className="social-med">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/kilkaricarefoundation"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/kilkaricarefoundation/"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/kilkaricarefoun"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCb2NyI6ZzX4TbZnuGMqLK3A"
                        target="_blank"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div
          className="whatsapp-footer"
          style={{ position: "fixed", right: 20, bottom: 30 }}
        >
          <div className="whatsapp-fix">
            <a
              href="https://wa.me/919971911148?text=Hi"
              target="_blank"
              style={{ fontSize: 20 }}
            >
              <div style={{ display: "flex" }}>
                <img
                  src="/img/whatsapp.png"
                  style={{ width: 40, height: 40 }}
                />
              </div>
            </a>
          </div>
        </div>

        <section className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="copyright-text">
                  <p>
                    COPYRIGHT © 2021 KILKARI CARE FOUNDATION. ALL RIGHTS
                    RESERVED.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </div>
  );
};

export default Footer;
