import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import BankDetails from "./Pages/BankDetails";
import Certificates from "./Pages/Certificates";
import DonationReceipt from "./Pages/DonationReceipt";
import kilkariReceipt from "./Pages/DonationReceipt";
import SingleStudent from "./Pages/SingleStudent";
import SingleSuccessStorie from "./Pages/SingleSuccessStorie";
import SingleUpcomingProject from "./Pages/SingleUpcomingProject";
import Student from "./Pages/Student";
import SuccessStorie from "./Pages/SuccessStorie";
import ThankYou from "./Pages/ThankYou";
const About = lazy(() => import("./Pages/About"));
const BecomeAVolunteer = lazy(() => import("./Pages/BecomeAVolunteer"));
const Blogs = lazy(() => import("./Pages/Blogs"));
const Causes = lazy(() => import("./Pages/Causes"));
const Contact = lazy(() => import("./Pages/Contact"));
const Donate = lazy(() => import("./Pages/Donate"));
const Events = lazy(() => import("./Pages/Events"));
const Faqs = lazy(() => import("./Pages/Faqs"));
const Projects = lazy(() => import("./Pages/Projects"));
const SingleBlog = lazy(() => import("./Pages/SingleBlog"));
const SingleCause = lazy(() => import("./Pages/SingleCause"));
const SingleEvent = lazy(() => import("./Pages/SingleEvent"));
const SinglePage = lazy(() => import("./Pages/SinglePage"));
const SingleProject = lazy(() => import("./Pages/SingleProject"));
const UpcomingProjects = lazy(() => import("./Pages/UpcomingProjects"));

const Home = lazy(() => import("./Pages/Home"));
const ErrorPage = lazy(() => import("./Pages/ErrorPage"));

import BeforeLoginRoutes from "./Routes/BeforeLoginRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

const renderLoader = () => (
  <img
    src="/images/spinner.gif"
    style={{ width: "200px", margin: "auto", display: "block" }}
    alt="Loading..."
  />
);
function Routes() {
  return (
    <Suspense fallback={renderLoader()}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/certificates" component={Certificates} />
        <Route exact path="/bank-details" component={BankDetails} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/donate-now" component={Donate} />

        <Route exact path="/thank-you" component={ThankYou} />
        <Route exact path="/projects" component={Projects} />
        <Route exact path="/projects/:slug" component={SingleProject} />
        <Route exact path="/upcoming-projects" component={UpcomingProjects} />
        <Route exact path="/success-stories" component={SuccessStorie} />
        <Route exact path="/success-stories/:slug" component={SingleSuccessStorie} />
        <Route exact path="/students" component={Student} />
        <Route exact path="/students/:slug" component={SingleStudent} />
        <Route
          exact
          path="/upcoming-projects/:slug"
          component={SingleUpcomingProject}
        />
        <Route exact path="/causes" component={Causes} />
        <Route exact path="/causes/:slug" component={SingleCause} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/events/:slug" component={SingleEvent} />
        <Route exact path="/blogs" component={Blogs} />
        <Route exact path="/faqs" component={Faqs} />
        <Route exact path="/blogs/:slug" component={SingleBlog} />
        <Route exact path="/policy/:slug" component={SinglePage} />
        <Route exact path="/become-a-volunteer" component={BecomeAVolunteer} />
        <Route exact path="/donations/:id" component={DonationReceipt} />

        <Route component={ErrorPage} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
