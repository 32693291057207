import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer";
import Header from "../components/Header";

function ThankYou() {
  return (
    <div>
      <Header />
      <BreadCrumb title={"Thank You"} />
      <div
        className="donate-form text-center"
        style={{ border: "1px solid rgb(241, 241, 241)", padding: "20px 0px" }}
      >
        <h2 style={{ textAlign: "center" }}>
          Thank you for your great generosity!
        </h2>
        <p style={{ padding: "20px 0px" }}>
          We, at Kilkari Care Foundation, greatly appreciate your donation. It
          is so heartening when we receive a gift from a generous donor.
        </p>
        <p className="special">
          Your support is invaluable to us, thank you again!
        </p>
        <p>Follow Us on Social Media for latest updates:</p>
      </div>

      <Footer />
    </div>
  );
}

export default ThankYou;
