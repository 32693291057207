import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Navbar from './common/Navbar';
import { getDonations } from '../actions/donations';

const Header = ({ match, getDonations, donation: { donations } }) => {
  const [activeMenu, setActiveMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (match && match.params.id) {
      window.scrollTo(0, 0);
      setActiveMenu(false);
    }
  }, [match]);
  // console.log("DONATION", donations);
  return (
    <div>
      <header>
        <div className='header'>
          <div className='container'>
            <div className='header-flex'>
              <div className='top-social'>
                <ul>
                  <li>
                    <a
                      href='https://www.facebook.com/kilkaricarefoundation'
                      target='_blank'
                    >
                      <i className='fa fa-facebook' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/kilkaricarefoundation/'
                      target='_blank'
                    >
                      <i className='fa fa-instagram' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://twitter.com/kilkaricarefoun'
                      target='_blank'
                    >
                      <i className='fa fa-twitter' />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.youtube.com/channel/UCb2NyI6ZzX4TbZnuGMqLK3A'
                      target='_blank'
                    >
                      <i className='fa fa-youtube' />
                    </a>
                  </li>
                </ul>
              </div>
              <div className='mb-d text-left'>
                <div className>
                  <a href="/donate-now" className='btn btn-donate'>
                    DONATE NOW
                  </a>
                </div>
              </div>
              <div className='top-number'>
                <div className='top-number-ul'>
                  <ul>
                    <li>
                      <a href='tel:987-333-6611'>
                        <i className='fa fa-phone' /> 987-333-6611
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <i className='fa fa-envelope' />
                        contact@kilkaricarefoundation.org
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className='banner-s'>
          <div className='container'>
            <div className='navbar-ab'>
              <div className='nav-flex'>
                <div className='logo'>
                  <Link to='/'>
                    <img src='/images/kilkarilogo.png' />
                  </Link>
                </div>
                <div>
                  <Navbar
                    setActiveMenu={setActiveMenu}
                    activeMenu={activeMenu}
                  />
                </div>
                <div className='desktop-only' style={{ paddingTop: 15 }}>

                  <a href="/donate-now" className='btn btn-donate-header'>
                    Donate Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end header */}
      </header>
    </div>
  );
};
const mapStateToProps = (state) => ({ donation: state.donation });

const mapDispatchToProps = { getDonations };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
