import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import BreadCrumbWithoutImage from "../components/BreadCrumbWithoutImage";
import { getStudentBySlug } from "../actions/students";
import Skeleton from "react-loading-skeleton";
import { URI } from "../constants/constants";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import Gallery from "../components/common/Gallery";
import PaymentForm from "../components/common/PaymentForm";

export const SingleStudent = ({
    getStudentBySlug,
    match,
    student: { loading, student },
    donation: { donations },
}) => {

    useEffect(() => {
        getStudentBySlug(match.params.slug);
    }, [match.params.slug]);
    const openFacebookURL = () => {
        console.log(window.location.pathname);
        const url = `https://www.kilkaricarefoundation.org${window.location.pathname}`;

        window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
            "facebook-share-dialog",
            "width=626,height=436"
        );
    };
    const [totalAchieved, setTotalAchieved] = useState(0);
    useEffect(() => {
        if (donations && student) {
            const filteredData = donations.filter(
                (donation) =>
                    donation.project == `/students/${student.slug}` && donation.is_paid
            );
            // console.log("FILTERED DATA", filteredData);
            let sumValue = 25000;
            if (filteredData.length > 0) {
                sumValue =
                    sumValue +
                    filteredData
                        .map((item) => item.amount)
                        .reduce((prev, next) => prev + next);
            }

            setTotalAchieved(sumValue);
        }
    }, [donations, student]);
    return (
        <div>
            <div>
                <Header />
                <BreadCrumbWithoutImage />
                {!loading ? (
                    student && (
                        <section className=" donate-page">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="single-cause-box">
                                            <div className="single-cause-name">
                                                <h3> {student.name} </h3>
                                            </div>
                                            <div className="share-btn">
                                                <a
                                                    href="#"
                                                    onClick={() => openFacebookURL()}
                                                    className="btn btn-facebook m-1"
                                                >
                                                    <i className="fa fa-facebook" /> Facebook
                                                </a>
                                                <a
                                                    href={`https://api.whatsapp.com/send?text=https://www.kilkaricarefoundation.org${window.location.pathname}`}
                                                    className="btn btn-whatsapp"
                                                    target="_blank"
                                                >
                                                    <i className="fa fa-whatsapp" /> Whatsapp
                                                </a>
                                            </div>

                                            <div
                                                className="urgent-flex"
                                                style={{
                                                    position: "absolute",
                                                    width: "100%",
                                                    padding: "0px 0px",
                                                }}
                                            >
                                                <div>
                                                    <a href="#" className="btn btn-verified">
                                                        Urgent
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="#" className="btn btn-verified-header">
                                                        <i className="fa fa-check" /> Verified by Kilkari
                                                        Care Foundation
                                                    </a>
                                                </div>
                                            </div>
                                            <img
                                                src={
                                                    student.featured_img
                                                        ? `${URI}${student.featured_img.url}`
                                                        : "/images/dreamstime_xxl_21162621-min-scaled.jpg"
                                                }
                                                style={{
                                                    marginBottom: 10,
                                                    height: "500px",
                                                    width: "100%",
                                                    objectFit: "contain",
                                                    objectPosition: "top",
                                                }}
                                            />
                                            <div className="single-cause-padd">
                                                {
                                                    //   <div className="box-content-flex">
                                                    //   <div className>
                                                    //     <h5>Goal</h5>
                                                    //     <span>₹ {cause.goal}</span>
                                                    //   </div>
                                                    //   <div className="bor" />
                                                    //   <div className="fifty">
                                                    //     <h6>
                                                    //       {parseInt((cause.achieved / cause.goal) * 100)} %
                                                    //     </h6>
                                                    //   </div>
                                                    //   <div className="bor" />
                                                    //   <div className>
                                                    //     <h5>Raised</h5>
                                                    //     <span>₹ {cause.achieved}</span>
                                                    //   </div>
                                                    // </div>

                                                    //   <div
                                                    //   className="give-progress-bar"
                                                    //   role="progressbar"
                                                    //   aria-valuemin={0}
                                                    //   aria-valuemax={100}
                                                    //   aria-valuenow={100}
                                                    // >
                                                    //   <span
                                                    //     style={{
                                                    //       width: `${parseInt(
                                                    //         (totalAchieved / cause.goal) * 100
                                                    //       )}%`,
                                                    //       background: "#D54427",
                                                    //     }}
                                                    //   />
                                                    // </div>
                                                }

                                                <div className="single-cause-name">


                                                    <div>
                                                        {student.description && renderHTML(student.description)}
                                                    </div>
                                                    {student.gallery && student.gallery.length > 0 && (
                                                        <>
                                                            <h3>Documents</h3>
                                                            <div className="">
                                                                <Gallery gallery={student.gallery} />
                                                            </div>
                                                        </>
                                                    )}

                                                    <PaymentForm singleOnly={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className>
                                            <div className="page-heading">
                                                <div className="sidebar-form">
                                                    {
                                                        //   <div className="box-content-flex">
                                                        //   <div className>
                                                        //     <h5>Goal</h5>
                                                        //     <span>₹ {cause.goal} </span>
                                                        //   </div>
                                                        //   <div className="bor" />
                                                        //   <div className="fifty">
                                                        //     <h6>
                                                        //       {parseInt((cause.achieved / cause.goal) * 100)}%
                                                        //     </h6>
                                                        //   </div>
                                                        //   <div className="bor" />
                                                        //   <div className>
                                                        //     <h5>Raised</h5>
                                                        //     <span>₹ {cause.achieved}</span>
                                                        //   </div>
                                                        // </div>

                                                        //   <div
                                                        //   className="give-progress-bar"
                                                        //   role="progressbar"
                                                        //   aria-valuemin={0}
                                                        //   aria-valuemax={100}
                                                        //   aria-valuenow={100}
                                                        // >
                                                        //   <span
                                                        //     style={{
                                                        //       width: `${parseInt(
                                                        //         (cause.achieved / cause.goal) * 100
                                                        //       )}%`,
                                                        //       backgroundColor: "#084a5a",
                                                        //     }}
                                                        //   />
                                                        // </div>
                                                    }

                                                    <PaymentForm singleOnly={true} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )
                ) : (
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <Skeleton width="100%" height="100vh" />
                            </div>
                            <div className="col-md-4">
                                <Skeleton width="100%" height="415px" />
                            </div>
                        </div>
                    </div>
                )}

                <Footer />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    student: state.student,
    donation: state.donation,
})

const mapDispatchToProps = { getStudentBySlug }

export default connect(mapStateToProps, mapDispatchToProps)(SingleStudent)