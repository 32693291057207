import {
    GET_SUCCESS_STORIES,
    TOTAL_SUCCESS_STORIES,
    ADD_SUCCESS_STORIE,
    EDIT_SUCCESS_STORIE,
    GET_SUCCESS_STORIE,
    RESET_SUCCESS_STORIE,
    SUCCESS_STORIES_ERROR,
} from "../actions/types";

const initialState = {
    success_stories: null,
    success_storie: null,
    total_success_stories: 0,
    loading: true,
    error: {},
    success_storie_message: null,
};

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_SUCCESS_STORIES:
            return {
                ...state,
                success_stories: payload,
                loading: false,
                error: {},
            };

        case TOTAL_SUCCESS_STORIES:
            return {
                ...state,
                total_success_stories: payload,
                loading: false,
                error: {},
            };
        case RESET_SUCCESS_STORIE:
            return {
                ...state,
                success_stories: null,
                success_storie: null,
                loading: true,
                error: {},
            };

        case ADD_SUCCESS_STORIE:
            return {
                ...state,
                success_storie_message: payload,
                loading: false,
                error: {},
            };
        case GET_SUCCESS_STORIE:
            return {
                ...state,
                success_storie: payload,
                loading: false,
                error: {},
            };
        case EDIT_SUCCESS_STORIE:
            return {
                ...state,
                success_storie_message: payload,
                loading: false,
                error: {},
            };

        case SUCCESS_STORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
            };

        default:
            return state;
    }
}
