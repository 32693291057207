import React from "react";

export default function BreadCrumb({ title }) {
  return (
    <section className="page-banner">
      <div className="image-layer" />
      <div className="container">
        <h1 className="entry-title">{title}</h1>
      </div>
    </section>
  );
}
