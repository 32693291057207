import React, { useState, useEffect } from "react";
import { URI } from "../../constants/constants";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import { connect } from "react-redux";

export const StudentCard = ({ item, showFull, donation: { donations } }) => {
    const [totalAchieved, setTotalAchieved] = useState(0);

    useEffect(() => {
        if (donations) {
            const filteredData = donations.filter(
                (donation) =>
                    donation.project == `/students/${item.slug}` && donation.is_paid
            );
            // console.log("FILTERED DATA", filteredData);
            let sumValue = 25000;
            if (filteredData.length > 0) {
                sumValue =
                    sumValue +
                    filteredData
                        .map((item) => item.amount)
                        .reduce((prev, next) => prev + next);
            }

            setTotalAchieved(sumValue);
        }
    }, []);
    return (
        <div className={showFull ? "col-md-12" : "col-md-3"}>
            <Link to={`/students/${item.slug}`}>
                <div className="inner-box-students">
                    <img
                        src={
                            item.featured_img
                                ? `${URI}${item.featured_img.url}`
                                : "images/dreamstime_xxl_21162621-min-scaled.jpg"
                        }
                    />
                    <div className="box-content">
                        {
                            //   <div className="box-content-flex">
                            //   <div className>
                            //     <h5>GOAL</h5>
                            //     <span>₹ {item.goal}</span>
                            //   </div>
                            //   <div className="bor" />
                            //   <div className="fifty">
                            //     <h6>{parseInt((item.achieved / item.goal) * 100)}%</h6>
                            //   </div>
                            //   <div className="bor" />
                            //   <div className>
                            //     <h5>RAISED</h5>
                            //     <span>₹ {item.achieved}</span>
                            //   </div>
                            // </div>
                        }
                        <div className="project-heading">
                            <h3>{item.name}</h3>


                            <div className="success-stories-description" style={{ height: "100px", overflow: "hidden" }}>
                                {renderHTML(item.description)}
                            </div>
                        </div>
                    </div>
                    <div className="btn-flex">
                        <div className>
                            <a href="#" className="btn btn-donate-project-2">
                                DONATE US
                            </a>
                        </div>
                        <div className>
                            <a href="#" className="btn btn-view-details-2">
                                VIEW DETAILS
                            </a>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

const mapStateToProps = (state) => ({ donation: state.donation })

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(StudentCard)