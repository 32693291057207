import React from "react";

export default function BreadCrumbWithoutImage({ title }) {
  return (
    <>
      <section className="page-banner" style={{ padding: "55px 0px" }}>
        <div className="image-layer" />
      </section>
      <p className="text-center" style={{ paddingTop: "20px" }}>
        Your contributions are eligible for up to 50% tax benefit under section
        80G
      </p>
    </>
  );
}
