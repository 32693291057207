import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Certificates() {
  return (
    <div>
      <Header />
      <section className="page-banner">
        <div className="image-layer" />
        <div className="container">
          <h1 className="entry-title">Certificates</h1>
        </div>
      </section>
      <section className="certificates">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Kilkari Care Foundation is the best NGO in India for ever child
                education. In India, we are directly benefiting over thousands
                children and their families every year under welfare projects on
                education and healthcare in remote villages and slums across
                various states. With that in mind, there is no greater travesty
                than children being denied education, not because they’re
                unwilling, but because they cannot afford their books, uniforms
                and academic fee. All development work that we perform focus on
                children – The bright young minds of tomorrow – For their
                welfare and promising future.
              </p>
              <p>
                Kilkari Care Foundation has been registered under Societies
                Registration ACT of 1860.
              </p>
              <p>Registration number : 1823/2017</p>
              <p>NGO Darpan registration id : DL/2018/0198339</p>
              <p>
                For More Details{" "}
                <a
                  href="https://ngodarpan.gov.in/index.php/search/"
                  target="_blank"
                >
                  {" "}
                  Click Here{" "}
                </a>
              </p>
            </div>
            <div className="row">
              <div className="col-md-4">
                <a target="_blank" href="/images/certificate-pdf/80G&12A_Certificate_Kilkari_Care_Foundation_page-0001_1.pdf">
                  <div className="certificate-card">
                    <div className="certificate-card-img ">
                      <img src="/images/certificate-icon/80g_certi.jpg" alt="" />
                    </div>
                    <div className="certificate-card-title">
                      <p>Kilkari Care Foundation 80G</p>
                    </div>
                  </div>
                </a>

              </div>
              <div className="col-md-4">
                <a target="_blank" href="/images/certificate-pdf/80G&12A_Certificate_Kilkari_Care_Foundation_(1)_page-0001_2.pdf">
                  <div className="certificate-card">
                    <div className="certificate-card-img ">
                      <img src="/images/certificate-icon/12A_cert.jpg" alt="" />
                    </div>
                    <div className="certificate-card-title">
                      <p>Kilkari Care Foundation 12A</p>
                    </div>
                  </div>
                </a>

              </div>
              <div className="col-md-4">
                <a target="_blank" href="/images/certificate-pdf/NGO_REGISTRATION_CERTIFICATE__page-0001.pdf">
                  <div className="certificate-card">
                    <div className="certificate-card-img ">
                      <img src="/images/certificate-icon/ngo_registration_cert.jpg" alt="" />
                    </div>
                    <div className="certificate-card-title">
                      <p>Registration Certificate</p>
                    </div>
                  </div>
                </a>

              </div>
              <div className="col-md-4">
                <a target="_blank" href="/images/certificate-pdf/41ae8522-8bb2-4219-ba0c-fd513dac42a9.pdf">
                  <div className="certificate-card">
                    <div className="certificate-card-img ">
                      <img src="/images/certificate-icon/ngo_pan_card_cert.jpg" alt="" />
                    </div>
                    <div className="certificate-card-title">
                      <p>Pan Card</p>
                    </div>
                  </div>
                </a>
              </div>


            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Certificates;
