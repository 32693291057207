import {
  GET_UPCOMING_PROJECTS,
  TOTAL_UPCOMING_PROJECTS,
  ADD_UPCOMING_PROJECT,
  EDIT_UPCOMING_PROJECT,
  GET_UPCOMING_PROJECT,
  RESET_UPCOMING_PROJECT,
  UPCOMING_PROJECTS_ERROR,
} from "../actions/types";

const initialState = {
  upcoming_projects: null,
  upcoming_project: null,
  total_upcoming_projects: 0,
  loading: true,
  error: {},
  upcoming_project_message: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_UPCOMING_PROJECTS:
      return {
        ...state,
        upcoming_projects: payload,
        loading: false,
        error: {},
      };

    case TOTAL_UPCOMING_PROJECTS:
      return {
        ...state,
        total_upcoming_projects: payload,
        loading: false,
        error: {},
      };
    case RESET_UPCOMING_PROJECT:
      return {
        ...state,
        upcoming_projects: null,
        upcoming_project: null,
        loading: true,
        error: {},
      };

    case ADD_UPCOMING_PROJECT:
      return {
        ...state,
        upcoming_project_message: payload,
        loading: false,
        error: {},
      };
    case GET_UPCOMING_PROJECT:
      return {
        ...state,
        upcoming_project: payload,
        loading: false,
        error: {},
      };
    case EDIT_UPCOMING_PROJECT:
      return {
        ...state,
        upcoming_project_message: payload,
        loading: false,
        error: {},
      };

    case UPCOMING_PROJECTS_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
}
